<template>
<div class="d-flex flex-column-fluid pt-0">
<div class="container action-page-container">
    <div class="card card-custom">
        <div class="card-body">
            <h2 class="content-title">Download File</h2>
            <div class="row-download-container row align-items-center h-100">
                <div class="col-sm-12 col-md-6">
                    <div class="action-pages">
                        <div class="action-inner-content">
                            <form>
                                    <h3>Download User Report</h3>
                                <div class="form-group">
                                    <label>School Name</label>
                                    <select class="form-control form-control-solid">
                                        <option>Select School</option>
                                        <option>School One</option>
                                        <option>School Two</option>
                                        <option>School Three</option>
                                        <option>School Four</option>
                                    </select>
                                </div>
                                
                                <button type="submit" class="action-submit btn btn-primary">Download</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="action-pages">
                        <div class="action-inner-content">
                            <form>
                                <h3>Download Kinnection Report</h3>
                                <div class="form-group">
                                        
                                    <label>Teacher Name</label>
                                    <select class="form-control form-control-solid">
                                        <option>Select Teacher</option>
                                        <option>Teacher One</option>
                                        <option>Teacher Two</option>
                                        <option>Teacher Three</option>
                                        <option>Teacher Four</option>
                                    </select>
                                </div>
                                <button type="submit" class="action-submit btn btn-primary">Download</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
})
</script>
